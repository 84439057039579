import React from "react";
import { Link as GatsbyLink } from "gatsby";

import { H3, H4, Paragraph, TextContainer, Ul, useTabSet } from "@siteimprove/fancylib";
import {
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	Header,
} from "../../../../../src/docs";
import { Code, CodeBlock } from "../../../../../src/docs/docs-code";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { useLearnInfoBox, LearnContent } from "./learn-info-box";

export const Meta: DocPageMeta = {
	category: "Feedback",
	title: "Learn Info Box",
	notepad: "https://hackmd.io/u20IJCAaTKyQZ3tAUQI2Pg",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Learn Info Box"
			subTitle="Guides users by introducing the page's content and setting expectations"
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<Header.H2>Examples</Header.H2>
				<Header.H3>Default</Header.H3>
				<Paragraph>
					<b>Composition</b>:
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Title</b>: A brief, descriptive heading summarizing the topic.
						</>,
						<>
							<b>Illustration</b>: A visual element to enhance recognition and engagement.
						</>,
						<>
							<b>Body</b>: Detailed information, presented in a structured way using headings,
							bullet points, tabs for organization.
						</>,
						<>
							<b>Call to Action</b> (optional): Buttons or links encouraging further exploration.
						</>,
					]}
				/>
				<Paragraph>
					<b>Default (Collapsed)</b>
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Purpose</b>: Provide a concise summary or preview of the page's content.
						</>,
						<>
							<b>Best Practices</b>:
							<Ul
								items={[
									<>Limit the summary to 1-2 sentences.</>,
									<>Include a clear call to action (e.g., "Learn More") to expand for details.</>,
									<>
										Use an illustration as a visual cue to help users quickly recognize the
										content's purpose.
									</>,
								]}
							/>
						</>,
					]}
				/>
				<Example fn={DefaultExample} />
				<Header.H3>Open Expanded</Header.H3>
				<Ul
					items={[
						<>
							<b>Purpose</b>:
							<Ul
								items={[
									<>Provide detailed information upfront when space is not a constraint.</>,
									<>Guide first-time users through onboarding processes.</>,
								]}
							/>
						</>,
						<>
							<b>Best Practices</b>:
							<Ul
								items={[
									<>Organize content with clear headings, bullet points, or short paragraphs.</>,
									<>Include a "Close" option to collapse the box.</>,
								]}
							/>
						</>,
					]}
				/>
				<Example fn={OpenExpandedExample} />
				<Header.H3>With tabs</Header.H3>
				<Ul
					items={[
						<>
							<b>Purpose</b>: Present content categorized into distinct sections.
						</>,
						<>
							<b>Best Practices</b>:
							<Ul
								items={[
									<>Use clear, descriptive tab labels.</>,
									<>Limit the number of tabs to 3-5.</>,
									<>Ensure each tab's content is focused and relevant to its label.</>,
								]}
							/>
						</>,
					]}
				/>
				<Example fn={TabsExample} />
				<Header.H2>Properties</Header.H2>
				<CodeBlock language="tsx">
					{`
						/** Title of the learn info box*/
						header: string;
						OR
						/** Tabs for the tabset within the learn info box */
						tabs: ReactElement<TabsProps>;

						AND

						/** Content of the learn info box */
						content: ReactNode;
						/** Description of the info box */
						"aria-label"?: string;
						/** Renders the learn info box in expanded state to start with */
						openExpanded?: boolean;
						/** Callback that is called when the learn info box changes between expanded and collapsed state */
						onToggle?: (expanded: boolean) => void;
						/** Custom className that's applied to the outermost element (only intended for special cases) */
						className?: string;
						/** Style object to apply custom inline styles (only intended for special cases) */
						style?: CSSProperties;
						/** Unique string, used by external script e.g. for event tracking */
						"data-observe-key"?: string;
					`}
				</CodeBlock>
			</TextContainer>
			<TextContainer article>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>LearnInfoBox</Code> when
					</Paragraph>
					<Ul
						items={[
							<>You need to preview the page's content or functionality.</>,
							<>You want to guide users on how to achieve their goals on the page.</>,
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>LearnInfoBox</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>At the top of the page, just below the main header.</>,
							<>Before the main content begins.</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								Siteimprove Design System: Adhere to Siteimprove's guidelines for color, typography,
								and spacing. If you are not using a component from Fancy, match the styling of your{" "}
								<Code>LearnInfoBox</Code> to existing components for visual consistency.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>The information is already clearly conveyed elsewhere on the page.</>,

							<>
								The content doesn't provide substantial support or guidance. Use a different
								component (e.g.,{" "}
								<GatsbyLink to="/lab/components/Feedback/Message">Message</GatsbyLink> ,{" "}
								<GatsbyLink to="/lib/components/Text/Paragraph">Paragraph</GatsbyLink>) is more
								suitable.{" "}
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<H4>For designers</H4>
					<Ul
						items={[
							<>
								Use sufficient color contrast. Make sure all interactive elements have clear labels
								and focus states.
							</>,
						]}
					/>
					<H4>For developers</H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={["Use plain language and avoid jargon", "Prioritize clarity and conciseness."]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const DefaultExample = () => {
	const [button, content] = useLearnInfoBox({
		heading: "Learn info box example",
		content: (
			<LearnContent
				columns={[
					<TextContainer key={1}>
						<H3>Lorem ipsum</H3>
						<Paragraph>
							Nullam consequat ligula sit amet diam congue porttitor. Etiam finibus dignissim
							accumsan.
						</Paragraph>
					</TextContainer>,
					<TextContainer key={2}>
						<H3>Etiam finibus</H3>
						<Paragraph>
							In hac habitasse platea dictumst. Sed ut perspiciatis unde omnis iste natus error sit
							voluptatem accusantium doloremque laudantium. Et harum quidem rerum facilis est et
							expedita distinctio.
						</Paragraph>
					</TextContainer>,
				]}
			/>
		),
	});
	return (
		<>
			<div style={{ display: "flex", justifyContent: "flex-end" }}>{button}</div>
			{content}
		</>
	);
};

const OpenExpandedExample = () => {
	const [button, content] = useLearnInfoBox({
		heading: "Learn info box example",
		content: (
			<LearnContent
				columns={[
					<TextContainer key={1}>
						<H3>Lorem ipsum</H3>
						<Paragraph>
							Nullam consequat ligula sit amet diam congue porttitor. Etiam finibus dignissim
							accumsan.
						</Paragraph>
					</TextContainer>,
					<TextContainer key={2}>
						<H3>Etiam finibus</H3>
						<Paragraph>
							In hac habitasse platea dictumst. Sed ut perspiciatis unde omnis iste natus error sit
							voluptatem accusantium doloremque laudantium. Et harum quidem rerum facilis est et
							expedita distinctio.
						</Paragraph>
					</TextContainer>,
				]}
			/>
		),
		openExpanded: true,
	});
	return (
		<>
			<div style={{ display: "flex", justifyContent: "flex-end" }}>{button}</div>
			{content}
		</>
	);
};

const TabsExample = () => {
	const [selectedTab, setSelectedTab] = React.useState(0);
	const [tabs, tabContent] = useTabSet({
		tabs: [
			{
				header: "One",
				content: (
					<LearnContent
						columns={[
							<TextContainer key={1}>
								<H3>Lorem ipsum</H3>
								<Paragraph>
									Nullam consequat ligula sit amet diam congue porttitor. Etiam finibus dignissim
									accumsan.
								</Paragraph>
							</TextContainer>,
						]}
					/>
				),
			},
			{
				header: "Two",
				content: (
					<LearnContent
						columns={[
							<TextContainer key={2}>
								<H3>Etiam finibus</H3>
								<Paragraph>In hac habitasse platea dictumst. Phasellus eu faucibus eros.</Paragraph>
							</TextContainer>,
						]}
					/>
				),
			},
			{
				header: "Three",
				content: (
					<LearnContent
						columns={[
							<TextContainer key={3}>
								<H3>Aliquam dignissim</H3>
								<Paragraph>
									Quisque fringilla metus ac enim pretium, quis sodales felis aliquet. Aenean
									bibendum ligula id porta facilisis.
								</Paragraph>
							</TextContainer>,
							<TextContainer key={4}>
								<H3>Aliquam dignissim</H3>
								<Paragraph>
									Quisque fringilla metus ac enim pretium, quis sodales felis aliquet. Aenean
									bibendum ligula id porta facilisis.
								</Paragraph>
							</TextContainer>,
						]}
					/>
				),
			},
		],
		selectedTab: selectedTab,
		onChange: setSelectedTab,
	});
	const [button, content] = useLearnInfoBox({
		heading: "Learn info box heading",
		tabs,
		content: tabContent,
	});
	return (
		<>
			<div style={{ display: "flex", justifyContent: "flex-end" }}>{button}</div>
			{content}
		</>
	);
};
